import React from "react";
import Layout from "../components/layout.js";
import SEO from "components/seo";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { colors } from "../style/theme.js";
import styled from "@emotion/styled";
import { mq } from "../utils/helper";

const designSystem = {
  a: styled.a({
    color: colors.primary
  }),
  p: styled.p({
    fontSize: "25px",
    color: colors.light,
    margin: 0,
    marginBottom: "2rem",
  }),
  li: styled.li({
    color: colors.light,
    fontSize: "23px",
    marginLeft: "1.2rem",
  }),
}

const P = designSystem.p;

function Engage({ data }) {
  return (
    <div css={{ backgroundColor: colors.dark }}>
      <SEO title="Contact us" />
      <img
        src="/assets/engage-background.gif"
        css={{
          position: "absolute",
          top: "15%",
          left: "-15%",
          width: "100%",
          height: "auto",
          zIndex: 0,
          opacity: 0.45
        }}
      />
      <Layout css={{ zIndex: 2 }}>
        <div
          css={{
            maxWidth: "1200px",
            margin: "100px auto",
            position: "relative",
            zIndex: 2
          }}
        >
          <h2
            css={{
              color: colors.primary,
              margin: 0,
              marginBottom: "100px",
              fontSize: "48px",
              display: "block",
              [mq[1]]: {
                marginLeft: "2rem",
                marginRight: "2rem"
              }
            }}
          >
            {data.pageContent.frontmatter.title}
          </h2>
          <div
            css={{
              maxWidth: "800px",
              marginLeft: "auto",
              [mq[1]]: {
                marginLeft: "2rem",
                marginRight: "2rem"
              }
            }}
          >
            <MDXProvider
              components={{
                a: designSystem.a,
                p: designSystem.p,
                li: designSystem.li,
              }}
            >
              <MDXRenderer>{data.pageContent.body}</MDXRenderer>
            </MDXProvider>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6rem",
                [mq[1]]: {
                  display: "block"
                }
              }}
            >
              <P css={{ marginBottom: 0 }}>
                If you know of information that needs preserving
              </P>
              <a
                css={{
                  fontSize: "20px",
                  color: colors.light,
                  border: `3px solid ${colors.primary}`,
                  padding: "0.4rem 1rem 0rem 1rem",
                  ":hover": {
                    color: colors.primary
                  },
                  [mq[1]]: {
                    marginTop: "2rem",
                    display: "inline-block"
                  }
                }}
                href="mailto:rapid-response@mnemonic.org"
              >
                Contact us
              </a>
            </div>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6rem",
                [mq[1]]: {
                  display: "block"
                }
              }}
            >
              <P css={{ marginBottom: 0 }}>For press related enquiries</P>
              <a
                css={{
                  fontSize: "20px",
                  color: colors.light,
                  border: `3px solid ${colors.primary}`,
                  padding: "0.4rem 1rem 0rem 1rem",
                  ":hover": {
                    color: colors.primary
                  },
                  [mq[1]]: {
                    marginTop: "2rem",
                    display: "inline-block"
                  }
                }}
                href="mailto:press@mnemonic.org"
              >
                Contact us
              </a>
            </div>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6rem"
              }}
            >
              <P>
                If you want to send an encrypted email you can find{" "}
                <a
                  css={{
                    color: colors.primary
                  }}
                  href="/pgp-public-key.txt"
                >
                  our public key here.
                </a>
              </P>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Engage;

export const pageQuery = graphql`
  query contactPage {
    pageContent: mdx(fileAbsolutePath: {regex: "/content/about/"}, slug: {eq: "about/contact.en"}) {
      id
      slug
      body
      frontmatter {
        title
      }
    }
  }
`;
